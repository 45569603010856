//import {version, name} from './package.json'
import packageInfo from './package.json'

// App configs
export const APP_VERSION = packageInfo.version
export const APP_DESC = packageInfo.description
export const APP_PATH = process.env.APP_PATH
export const APP_SITE = process.env.APP_SITE
export const APP_NAME = packageInfo.name
export const DS_VERSION = packageInfo.dependencies['@aspendental/design-system']
export const APP_BG_COLOR = process.env.APP_BG_COLOR || '#ffffff'
export const APP_COLOR = process.env.APP_COLOR || '#002954'
export const APP_ICON = process.env.APP_ICON || 'src/images/aspendental-icon.png'
export const BUILD_DATE = new Date().toISOString()

// Bugsnag
export const BUGSNAG_KEY = process.env.GATSBY_BUGSNAG_API_KEY
export const BUGSNAG_NOTIFY_URL = 'https://bugsnag-notify.aspendental.com'
export const BUGSNAG_SESSIONS_URL = 'https://bugsnag-sessions.aspendental.com'

// Google Tag Manager
export const GTM_ID = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID
export const GTM_CONFIG = {
  prod: {
    id: process.env.GATSBY_GOOGLE_TAG_MANAGER_ID,
    // env: 'do-not-use',
    // auth: 'do-not-use'
  },
  stage: {
    id: process.env.GATSBY_GOOGLE_TAG_MANAGER_ID,
    // env: 'do-not-use',
    // auth: 'do-not-use'
  },
}

// Contentful CMS
export const CMS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN
export const CMS_PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
export const CMS_PREVIEW_SECRET = process.env.CONTENTFUL_PREVIEW_SECRET
export const CMS_SPACE_ID = process.env.CONTENTFUL_SPACE_ID
export const CMS_ENV = process.env.CONTENTFUL_ENVIRONMENT_ID

// Module Federation configs
// export const MF_PLATFORM_NAME = process.env.MF_PLATFORM_NAME
// export const MF_PLATFORM_REMOTE = process.env.MF_PLATFORM_REMOTE
