import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import type { GatsbyBrowser } from 'gatsby'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { APP_VERSION, BUGSNAG_KEY, BUGSNAG_NOTIFY_URL, BUGSNAG_SESSIONS_URL } from './constants'

import { BUGSNAG_ENV } from './config'

import './src/styles/global.css'

if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    appVersion: APP_VERSION,
    releaseStage: BUGSNAG_ENV,
    plugins: [new BugsnagPluginReact()],
    endpoints: {
      notify: BUGSNAG_NOTIFY_URL,
      sessions: BUGSNAG_SESSIONS_URL,
    },
  })
}

// const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

/**
 * This function is used to wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * @param {ReactNode} element The “Page” React Element built by Gatsby
 */

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <>{element}</>
}

/**
 * This function is used to always scroll to the top of the page when navigating.
 * Gatsby handles it by default with the scroll position which is not working properly
 * So handled in this hook to go to top always
 */
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  window.scrollTo(0, 0)
  return false
}

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
