declare global {
  interface Window {
    ENV: string
  }
}

type DeploymentEnv = 'prod' | 'stage'

export function resolveDeploymentEnv(): DeploymentEnv {
  const isProd = window.location.hostname.includes('www.')
  return isProd ? 'prod' : 'stage'
}

export const deploymentEnv = resolveDeploymentEnv()

const isProduction = deploymentEnv === 'prod'

export const BUGSNAG_ENV = isProduction ? 'production' : 'stage'

export const enableAmelia = isProduction ? false : true
